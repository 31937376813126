import React from 'react';
import styled from '@emotion/styled'
import { Inner } from 'styles/structure';
import dimensions from 'styles/dimensions';
import colors from 'styles/colors';
import ResourcesGrid from 'components/_grid/Resources';
import ResourceCard from 'components/_card/Resource';
import ArrowLink from 'components/_ui/ArrowLink';

const CollectionContainer = styled.div`
  & + * {
    margin-top: 6.4rem;
  }
  
  header {
    > div {
      > * + * {
        margin-top: 1.6rem;
      }
      
      & + * {
        margin-top: 3.2rem;
      }
    }
    
    & + * {
      margin-top: 4rem;
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {    
    & + * {
      margin-top: 8.8rem;
      
      header {
        padding-top: 7.2rem;
        border-top: 1px solid ${colors.grey400};
      }
    }
  
    header {
      display: flex;
      align-items: center;
      
      > div {
        flex: 1 1 100%;
        
        > * {
          max-width: 62.8rem;
        }
      }
      
      a {
        flex-shrink: 0;
      }
      
      & + * {
        margin-top: 5.6rem;
      }
    }
  }
`;

const NavAnchor = styled.a`
  display: block;
  position: relative;
  top: -16rem;
  visibility: hidden;
`;

const Collection = ({ collection }) => {
  const { data } = collection.collection.document;

  return (
    <CollectionContainer id={`${collection.collection.document.uid}-nav`}>
      <NavAnchor id={collection.collection.document.uid}/>

      <Inner>
        {data.title && (
          <header>
            <div>
              <h3>{data.title}</h3>

              {data.description && <p>{data.description}</p>}
            </div>

            <ArrowLink
              text={`View all resources on ${data.title}`}
              destination={`/topic/${collection.collection.document.uid}`}
            />
          </header>
        )}

        {(data.resources && data.resources.length > 0) && (
          <ResourcesGrid>
            {data.resources.map((resource,i) => {

              if (resource.resource) {
                const resourceData = resource.resource.document.data;

                return (
                  <li key={i}>
                    <ResourceCard
                      uid={resource.resource.document.uid}
                      image={resourceData.preview_image || resourceData.feature_image}
                      title={resourceData.title.text}
                      type={resourceData.resource_type.document.data.title}
                      topic={resourceData.topic.document.data.title}
                      preview_text={resourceData.preview_text}
                    />
                  </li>
                )
              } else {
                return null;
              }
            })}
          </ResourcesGrid>
        )}

      </Inner>
    </CollectionContainer>
  );
}

export default Collection;
