import React from 'react';
import { graphql } from 'gatsby';
import Seo from 'components/Seo';
import Layout from 'components/Layout';
import SubHero from 'components/SubHero';
import Collections from 'components/_page/resource/Collections';
import { withPreview } from 'gatsby-source-prismic';

const RenderBody = ({ landing }) => (
  <>
    <SubHero
      heading={landing.hero_heading}
      content={landing.hero_content}
    />

    {(landing.featured_collections && landing.featured_collections.length > 0) && (
      <Collections
        collections={landing.featured_collections}
      />
    )}
  </>
);

const CollectionsPage = ({ data }) => {
  //Required check for no data being returned
  const landing = data.allPrismicCollections.edges[0].node.data;

  return (
    <Layout>
      <Seo
        title={landing.social_title || 'Resource Collections'}
        description={landing.social_description}
        image={landing.social_image}
      />

      <RenderBody landing={landing} />
    </Layout>
  );
};

export const query = graphql`
  {
    allPrismicCollections {
      edges {
        node {
          data {
            hero_heading {
              raw
            }
            hero_content {
              raw
            }
            featured_collections {
              collection {
                document {
                  ... on PrismicCollection {
                    uid
                    data {
                      title
                      description
                      topic {
                        document {
                          ... on PrismicTopic {
                            uid
                            data {
                              title
                            }
                          }  
                        }
                      }
                      resources {
                        resource {
                          document {
                            ... on PrismicResource {
                              uid
                              data {
                                resource_type {
                                  document {
                                    ... on PrismicType {
                                      data {
                                        title
                                      }
                                    }
                                  }
                                }
                                topic {
                                  document {
                                    ... on PrismicTopic {
                                      data {
                                        title
                                      }
                                    }  
                                  }
                                }
                                title {
                                  raw
                                  text
                                }
                                preview_text
                                preview_image {
                                  url
                                  alt
                                }
                              }
                            }  
                          }
                        }
                      }
                    }
                  }  
                }
              }
            }
            social_title
            social_description
            social_image {
              url
            }    
          }
        }
      }
    }
  }
`;

export default withPreview(CollectionsPage);