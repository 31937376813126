import React from 'react';
import styled from '@emotion/styled'
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import CollectionsNav from 'components/_page/resource/CollectionsNav';
import Collection from 'components/_page/resource/Collection';

const CollectionsContainer = styled.nav``;

const CollectionsContent = styled.div`
  padding: 4rem 0;
  background-color: ${colors.grey100};
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 7.2rem 0 10.4rem;
  }
`;

const Collections = ({ collections }) => {
  const anchors = collections.map(collection => { return `${collection.collection.document.uid}-nav`});

  return (
    <CollectionsContainer>
      <CollectionsNav
        collections={collections}
        anchors={anchors}
      />

      <CollectionsContent>
        {(collections.length && collections.length > 0) && collections.map((collection, i) => (
          <Collection
            key={`collection-${i}`}
            collection={collection}
          />
        ))}
      </CollectionsContent>
    </CollectionsContainer>
  );
}

export default Collections;
