import { document, window } from 'browser-monads';

const smoothScroll = function(target, duration) {
  const elTarget = document.querySelector(target);
  const startPosition = window.pageYOffset || elTarget.scrollTop || document.body.scrollTop;
  const boxTop = elTarget.getBoundingClientRect().top;
  const clientTop = elTarget.clientTop || document.body.clientTop || 0;
  const endPosition = boxTop + startPosition - clientTop - 144;
  const distance = endPosition - startPosition;
  let startTime = null;

  function animation(currentTime) {
    if (startTime === null) startTime = currentTime;
    let timeElapsed = currentTime - startTime;
    const run = ease(timeElapsed, startPosition, distance, duration);

    if (timeElapsed < duration) {
      requestAnimationFrame(animation);
    }

    window.scrollTo(0,run);
  }

  function ease(t,b,c,d) {
    t /= d / 2;

    if(t<1) return c / 2 * t * t + b;
    t--;

    return -c / 2 * (t*(t-2)-1) + b;
  }

  requestAnimationFrame(animation);
}

export default smoothScroll;