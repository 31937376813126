import React, { Component } from 'react';
import styled from '@emotion/styled'
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';
import Scrollspy from 'react-scrollspy';
import { document, window } from 'browser-monads';
import smoothScroll from '../../../utils/smoothScroll';

const NavContainer = styled.nav`
  background-color: #fff;
  border-bottom: 1px solid ${colors.grey400};
  height: 6.4rem;
  overflow: hidden;
  position: sticky;
  top: 6.4rem;
  z-index: 100;
  
  &::before {
    content: '';
    display: block;
    position: absolute;
    right: 2rem;
    top: 0;
    height: 100%;
    width: 6.4rem;
    background: linear-gradient(90deg,rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%);
    z-index: 10;
    pointer-events: none;
  }
  
  ul {
    width: 100%;
    // extra bottom padding to hide scroll bar in certain user browser configurations
    padding: 2.4rem 0 4rem;
    overflow-x: scroll;
    white-space: nowrap;
  }
  
  li {
    display: inline-block;
    
    & + * {
      margin-left: 4.8rem;
    }
    
    &.is-current {
      a {
        color: ${colors.yellow500};
      
        &::before {
          transition: transform 0.2s ease-in-out, color 0.08s ease-in-out;
          visibility: visible;
          transform: scaleX(1);
          transform-origin: left center;
        }
      }
    }
  }
  
  a {
    position: relative;
    font-weight: 500;
    font-size: 1.4rem;
    transition: color 0.08s ease-in-out;
    
    &:hover {
      color: ${colors.yellow500};
    }
    
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: calc(100% + 0.4rem);
      width: 100%;
      height: 0.2rem;
      background-color: ${colors.yellow500};
      transform: scaleX(0);
      visibility: hidden;
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    top: 10.4rem;
    
    &::before {
      display: none;
    }
  }
`;

class CollectionsNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSticky: false,
      heroHeight: 0,
    }
  }

  onScroll = () => {
    if (!this.state.isSticky && window.pageYOffset >= (this.state.heroHeight * 0.8)) {
      this.setState({
        isSticky: true,
      });
    } else if (this.state.isSticky && window.pageYOffset < (this.state.heroHeight * 0.8)) {
      this.setState({
        isSticky: false,
      });
    }
  };

  onResize = () => {
    this.setState({
      heroHeight: 104,
    });
  };

  onClick = (e) => {
    e.preventDefault();

    smoothScroll(e.currentTarget.getAttribute('href'), 1000);
  };

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll.bind(this));
    document.addEventListener('resize', this.onResize.bind(this));

    this.onResize();
  }

  render() {
    const self = this;

    return (
      <NavContainer>
        <Inner>
          {(self.props.collections.length && self.props.collections.length > 0) && (
            <Scrollspy items={ self.props.anchors } offset={-160} currentClassName="is-current">
              {self.props.collections.map((collection, i) => (
                <li key={`collection-nav-${i}`}>
                  <a href={`#${collection.collection.document.uid}-nav`} onClick={(e) => this.onClick(e)}>{collection.collection.document.data.title}</a>
                </li>
              ))}
            </Scrollspy>
          )}

        </Inner>
      </NavContainer>
    );
  }
}

export default CollectionsNav;
